@import '../general/config';
@import '../general/breakpoint';

.e-notification{
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	padding: 10px;
	background: #2dbb79;
    transition: all linear .3s;
    z-index: 100;
    opacity: 0.9;

    &.success{
        background: #2dbb79;
    }

    &.warning{
        background: #d4cb5c;
    }

    &.error{
        background: #f18e8e;
    }

	.e-content-notification{
		width: 100%;
		height: 20px;
		line-height: 20px;
		display: block;
		color: #fff;
		font-weight: bold;
		font-size: 14px;
		letter-spacing: 1px;
		position: relative;
		text-align: center;

		.e-close{
			width: 20px;
			height: 20px;
			position: absolute;
			right: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			cursor: pointer;
		}
	}
}
