$font: "Nunito", cursive;
$font-logo: $font;

$color-white: #fff;
$color-success: #13b15f;
$color-medium: lighten(#ff9f1a, 10);
$color-error: #ff5454;

/*principais*/
$color-main: #2f55d4;
$color-main-dark: #2443ac;
$color-main-light: #4366d8;

$color-secondary: #13b15f;
$color-secondary-light: #36c97c;

/*textos*/
$color-text: #8492a6;
$color-placeholder: #ccc;
// $color-title: #353c50;
$color-title: #2c3554;
$color-background: #f6f7fa;
$color-white: #fff;
$color-white-hover: #d5ddf6;
$color-neutral: #9497a2;

// notification-message style
.MuiAlert-filledSuccess {
    background: $color-success;
    box-shadow: 0 0 20px rgba($color-success, 0.5);
}

.MuiAlert-filledError {
    background: $color-error;
    box-shadow: 0 0 20px rgba($color-error, 0.5) !important;
}

.MuiPaper-elevation1 {
    box-shadow: none;
    border: 1px solid #e6e6e6;
}

@mixin clearfix {
    clear: both;
    &:before {
        content: "";
        display: block;
    }
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin container {
    width: 100%;
    float: left;
    position: relative;
}

@mixin centerContent {
    .e-center-content {
        width: 95%;
        display: block;
        margin: 0 auto;
        padding: 40px 0;
        @include breakpoint("mobile") {
            width: 90%;
            padding: 20px 0;
        }
    }
}

@mixin slickList {
    .slick-list {
        @include container();
        overflow: hidden;
    }
}

@mixin spriteSetup {
    text-indent: -999em;
    text-align: left;
    overflow: hidden;
    display: block;
}

@mixin hide-text {
    font-size: 0;
    line-height: 0;
    color: transparent;
}

@mixin inline-block {
    display: inline-block;
    *zoom: 1;
    *display: inline;
}

@mixin box-shadow($string) {
    -ms-box-shadow: $string;
    -o-box-shadow: $string;
    -moz-box-shadow: $string;
    -webkit-box-shadow: $string;
    box-shadow: $string;
}

@mixin text-shadow($string) {
    -ms-text-shadow: $string;
    -o-text-shadow: $string;
    -moz-text-shadow: $string;
    -webkit-text-shadow: $string;
    text-shadow: $string;
}

@mixin background-size($string) {
    background-size: $string;
}

/**
 * Flexbox prefixes
 */
@mixin display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin flex-direction($direction) {
    @if $direction == column {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@mixin justify-content($position) {
    @if $position == center {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@mixin align-items($position) {
    @if $position == center {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    @if $position == flex-end {
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -moz-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
}

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin center {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
}

@mixin centerV {
    position: absolute;
    top: 50%;
    @include translate(0, -50%);
}

@mixin centerH {
    position: absolute;
    left: 50%;
    @include translate(-50%, 0);
}
@mixin scrollbar {
    &::-webkit-scrollbar-track {
        background-color: #ccc;
    }

    &::-webkit-scrollbar {
        background-color: #ccc;
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #212424;
    }
}

@mixin hiddenHeading {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px 1px 1px 1px); // for Internet Explorer
    clip: rect(1px, 1px, 1px, 1px);
}

@mixin linkHover($color) {
    color: $color;
    text-shadow: 0 0 0 $color, 0 0 0 $color, 0 0 0 $color;
}

@mixin padraoEditor() {
    p,
    li {
        font-size: 0.85rem;
        line-height: 1.6;
        color: $color-title;
    }

    h2,
    h3,
    h4 {
        text-align: left;
        font-weight: bold;
        text-transform: none;
        color: $color-text;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    ul,
    ol {
        list-style: inherit;
        padding-left: 20px;
    }

    ol {
        list-style: decimal;
    }

    blockquote,
    q {
        padding-left: 20px;
        margin-left: 5px;
        border-left: 3px solid lighten($color-title, 8);
    }

    figure {
        display: inline-block;
        table {
            tbody {
                tr {
                    td {
                        border: 1px solid lighten($color-title, 8);
                        padding: 5px;
                    }
                }
            }
        }
    }
}

/*KEY FRAMES*/
@-webkit-keyframes spinner-loading {
    0% {
        -webkit-transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes spinner-loading {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

/*move*/
@keyframes move {
    0% {
        top: -50px;
    }
    50% {
        top: -60px;
    }
    100% {
        top: -50px;
    }
}
@-webkit-keyframes move {
    0% {
        top: -50px;
    }
    50% {
        top: -60px;
    }
    100% {
        top: -50px;
    }
}
@-moz-keyframes move {
    0% {
        top: -50px;
    }
    50% {
        top: -60px;
    }
    100% {
        top: -50px;
    }
}

/*teste*/
@keyframes teste {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}
/*END KEY FRAMES*/

/**
  * SYSTEM BUTTONS 
*/
@mixin button($width, $height, $background, $color) {
    width: $width;
    height: $height;
    background: $background;
    color: $color;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:hover {
        background: darken($background, 5%);
    }
}
