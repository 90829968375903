@import "../../assets/scss/general/config";

html {
    body {
        .e-site {
            .e-main-page {
                .e-plans {
                    background-color: #f6f7fa;
                    padding: 60px 0;

                    &__container {
                        max-width: 1140px;
                        margin: 0 auto;

                        @media only screen and (max-width: 900px) {
                            width: 90vw;
                        }
                    }

                    .e-title {
                        font-size: 38px;
                        font-weight: bold;
                        color: $color-title;
                        line-height: 1.2;
                        margin-bottom: 60px;

                        span {
                            color: $color-main;
                        }
                    }

                    &__boxs {
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-right: -15px;
                        margin-left: -15px;
                        justify-content: space-between;

                        @media only screen and (max-width: 1070px) {
                            justify-content: center;

                            .e-box {
                                margin: 1rem !important;
                            }
                        }

                        .e-box {
                            position: relative;
                            background-color: $color-white;
                            border-radius: 5px;
                            box-shadow: 0px 5px 15px rgba($color-title, 0.15);
                            z-index: 1;
                            transition: 0.5s all ease;
                            overflow: hidden;
                            margin-bottom: 30px;
                            padding: 1rem 1.5rem;
                            margin: 1rem 0;

                            &:nth-child(2) {
                                transform: translateY(-50px);

                                @media only screen and (max-width: 900px) {
                                    transform: none;
                                }
                            }

                            &__title {
                                font-size: 18px;
                                font-weight: bold;
                                color: $color-title;
                                line-height: 1.2;
                                margin: 20px 30px;
                            }

                            &__price {
                                font-size: 42px;
                                font-weight: bold;
                                color: $color-title;
                                line-height: 1.2;
                                margin: 20px 30px;

                                span {
                                    font-size: 13px;
                                    font-weight: 600;
                                    color: $color-title;
                                }
                            }

                            p {
                                font-size: 15px;
                                line-height: 1.5;
                                color: $color-text;
                            }

                            ul {
                                li {
                                    font-size: 14px;
                                    line-height: 1.5;
                                    color: $color-text;
                                    padding: 10px 30px;
                                    border-bottom: 1px solid #eaeaea;

                                    .material-icons {
                                        position: relative;
                                        display: inline-block;
                                        top: 7px;
                                        margin-right: 4px;

                                        &.e-icon-done {
                                            color: $color-success;
                                        }

                                        &.e-icon-not {
                                            color: $color-error;
                                        }
                                    }
                                }
                            }

                            .e-btn-action {
                                text-align: center;
                                margin: 3rem 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
