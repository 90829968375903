@import "../../../assets/scss/general/config";

html {
    body {
        .e-site {
            .e-main-page {
                .e-terms-and-condition {
                    background-color: #f6f7fa;
                    padding: 60px 0;

                    &__container {
                        max-width: 1140px;
                        margin: 0 auto;
                    }

                    .e-title {
                        font-size: 38px;
                        font-weight: bold;
                        color: $color-title;
                        line-height: 1.2;
                        margin-bottom: 60px;

                        span {
                            color: $color-main;
                        }
                    }
                }

                .e-institutional-group {
                    &__description {
                        font-size: 14px;
                        color: $color-text;
                        line-height: 1.8;
                    }
                }
            }
        }
    }
}
