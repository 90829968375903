@import "../../assets/scss/general/config";

html {
    body {
        .e-site {
            .e-main-page {
                &.e-home {
                    padding-top: 0;

                    .e-onboard {
                        padding: 180px 0;
                        background: url("../../assets/images/site/bg-onboarding.png") no-repeat center -130px;
                        background-size: 100% 100%;

                        &__container {
                            max-width: 1140px;
                            margin: 0 auto;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        &__text {
                            width: 55%;
                            padding-right: 50px;

                            h1 {
                                font-size: 48px;
                                font-weight: bold;
                                color: $color-title;
                                line-height: 1.2;
                                margin-bottom: 20px;

                                span {
                                    color: $color-main;
                                }
                            }

                            p {
                                font-size: 18px;
                                line-height: 1.5;
                                color: $color-text;
                            }
                        }

                        &__bg {
                            width: 45%;

                            img {
                                max-width: 100%;
                                height: auto;
                                vertical-align: middle;
                            }
                        }

                        &__register {
                            max-width: 1140px;
                            margin: 40px auto;

                            &--description {
                                max-width: 460px;
                                margin-bottom: 10px;

                                h4 {
                                    font-size: 20px;
                                    font-weight: bold;
                                    color: $color-title;
                                    line-height: 1.2;
                                    margin-bottom: 10px;
                                }

                                p {
                                    font-size: 15px;
                                    line-height: 1.5;
                                    color: $color-title;
                                }
                            }

                            .e-form-group {
                                position: relative;
                                display: inline-block;
                                width: 100%;
                                max-width: 460px;
                                margin: 10px 0;

                                // &::after {
                                //    content: "";
                                //    position: absolute;
                                //    left: 0;
                                //    bottom: 0;

                                //    width: 3px;
                                //    height: 0;
                                //    background: $color-main;
                                //    transition: 0.2s ease-in-out;
                                // }

                                // &:focus-within {
                                //    .e-form-group__field {
                                //       border-radius: 0 10px 10px 0;
                                //    }

                                //    &::after {
                                //       top: 0;
                                //       height: 100%;
                                //    }

                                &__field {
                                    outline: none;

                                    // width: 100%;
                                    // color: $color-title;
                                    // border: 1px solid #e6e6e6;
                                    // padding-right: 130px;
                                    // padding-left: 30px;
                                    // border-radius: 10px;

                                    // &:valid {
                                    //    border-radius: 0 10px 10px 0;
                                    //    border-left: 3px solid $color-main;
                                    // }
                                }

                                &__submit {
                                    position: absolute;
                                    top: 50%;
                                    right: 8px;
                                    margin-top: -20px;
                                    padding: 8px 20px;
                                }

                                &__terms {
                                    font-size: 14px;
                                    font-weight: bold;
                                    color: $color-title;
                                    line-height: 1.2;
                                    margin-top: 10px;

                                    a {
                                        color: $color-main;
                                    }
                                }
                            }
                        }
                    }
                }

                .e-teams {
                    background-color: #f6f7fa;
                    padding: 60px 0 30px;
                    margin-top: -130px;

                    &__container {
                        max-width: 1140px;
                        margin: 0 auto;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    &__text {
                        width: 55%;
                        padding-right: 50px;

                        h2 {
                            font-size: 48px;
                            font-weight: bold;
                            color: $color-title;
                            line-height: 1.2;
                            margin-bottom: 20px;

                            span {
                                color: $color-main;
                            }
                        }

                        p {
                            font-size: 18px;
                            line-height: 1.5;
                            color: $color-title;
                            margin-bottom: 15px;
                        }

                        a {
                            color: $color-main;
                            font-weight: 700;
                            transition: color 0.2s ease-in-out;

                            &:hover {
                                color: lighten($color-main-light, 5);
                            }

                            span {
                                font-size: 24px;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                    }

                    &__bg {
                        width: 40%;

                        img {
                            max-width: 100%;
                            height: auto;
                            vertical-align: middle;
                        }
                    }
                }

                .e-resources {
                    background-color: #f6f7fa;
                    padding: 80px 0;

                    &__container {
                        max-width: 1140px;
                        margin: 0 auto;
                    }

                    .e-title {
                        font-size: 38px;
                        font-weight: bold;
                        color: $color-title;
                        line-height: 1.2;
                        margin-bottom: 60px;

                        span {
                            color: $color-main;
                        }
                    }

                    &__boxs {
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-right: -15px;
                        margin-left: -15px;

                        .e-box {
                            position: relative;
                            background-color: $color-white;
                            border-radius: 5px;
                            padding: 30px 30px 26px;
                            box-shadow: 0px 0px 20px 0px #dddddd7a;
                            z-index: 1;
                            -webkit-transition: 0.5s all ease;
                            overflow: hidden;
                            transition: 0.25s all ease;
                            margin-bottom: 30px;

                            flex: 0 0 33.333333%;
                            max-width: 30.333333%;
                            margin: 0 1.3% 3%;

                            user-select: none;

                            &:hover {
                                transform: translateY(-15px);
                                box-shadow: 5px 5px 15px rgba($color-title, 0.35);
                            }

                            &__title {
                                font-size: 18px;
                                font-weight: bold;
                                color: $color-title;
                                line-height: 1.2;
                                margin-bottom: 20px;
                            }

                            p {
                                font-size: 15px;
                                line-height: 1.5;
                                color: $color-text;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .e-header,
    .e-onboard,
    .e-teams,
    .e-resources,
    .e-partners,
    .e-footer {
        &__container,
        .e-onboard__register {
            padding: 0 2rem;
        }
    }
}

@media only screen and (max-width: 900px) {
    .e-onboard,
    .e-teams,
    .e-resources,
    .e-partners,
    .e-footer {
        &__container,
        .e-onboard__register {
            width: 90vw;
            flex-direction: column;
        }

        &__text {
            width: 100% !important;
            padding: 0 !important;
        }

        &__bg {
            width: 100% !important;
            margin: 3rem 0;
        }
    }

    .e-teams {
        &__bg {
            width: 80% !important;
        }
    }

    .e-resources__boxs {
        flex-direction: column;

        .e-box {
            max-width: 100% !important;

            &:hover {
                transform: none !important;
            }
        }
    }

    .e-partners__boxs {
        flex-direction: column;
        margin: 0 !important;

        .e-box {
            margin: 3rem 0;
        }
    }

    .e-footer__columns {
        &--items {
            margin-bottom: 2.3rem;
        }
    }
}
