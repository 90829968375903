@import '../general/config';

/**!
 * Initialize.css (v1.3.2) is a complete and customizable collection of CSS best practices based on Normalize.css and HTML5 Boilerplate.
 * http://jeroenoomsnl.github.io/initialize-css
 *
 * HTML5 Boilerplate: https://github.com/h5bp/html5-boilerplate
 * Normalize.css: http://github.com/necolas/normalize.css
 */

// ==========================================================================
// Config
// ==========================================================================

/**
 * Predefined values
 */
$initialize-box-sizing: border-box !default;
$initialize-font-family: "Roboto", sans-serif !default;
$initialize-font-size: 1em !default;
$initialize-line-height: 1.4 !default;

/**
  * Options
  */
$initialize-dinosaur-mode: true !default; // toggle support for IE8, IE9 & IE10
$initialize-print-styles: false !default; // toggle HTML5 Boilerplate print styles
$initialize-html5-boilerplate-overwrites: false !default; // toggle HTML5 Boilerplate overwrites

//
// Optional values
//
$initialize-color: null !default;
$initialize-heading-color: null !default;
$initialize-heading-margins: null !default;
$initialize-heading-font-family: null !default;
$initialize-block-margins: null !default;

// ==========================================================================
// Helpers
// ==========================================================================

/**
  * Mixin for optional properties
  */
@mixin optional-prop($property, $value) {
   @if $value != null {
      #{$property}: $value;
   }
}

/* ==========================================================================
    General
    ========================================================================== */

/**
  * 1. Set default font family to $initialize-font-family (default: sans-serif)
  * 2. Prevent adjustments of font size after orientation changes in IE and iOS.
  * 3. Configurable defaults
  */
html {
   @include optional-prop("color", $initialize-color);
   @include optional-prop("box-sizing", $initialize-box-sizing);

   font-family: $initialize-font-family; /* 1 */
   line-height: $initialize-line-height; /* 3 */
   font-size: $initialize-font-size; /* 3 */
   -ms-text-size-adjust: 100%; /* 2 */
   -webkit-text-size-adjust: 100%; /* 2 */
   -webkit-font-smoothing: antialiased;
}

/**
  * Remove the margin in all browsers
  */
body {
   margin: 0;
   -webkit-font-smoothing: antialiased;
}
/**
  * Inheriting box-sizing
  * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
  */

*,
*:before,
*:after {
   box-sizing: inherit;
}

/* ==========================================================================
    HTML5 display defenitions
    ========================================================================== */

/**
  * Add the correct display in IE 9-.
  * 1. Add the correct display in Edge, IE, and Firefox.
  * 2. Add the correct display in IE.
  */
@if $initialize-dinosaur-mode == true {
   article,
     aside,
     details, /* 1 */
     figcaption,
     figure,
     footer,
     header,
     hgroup,
     main, /* 2 */
     menu,
     nav,
     section,
     summary /* 1 */ {
      display: block;
   }
} @else {
   details, /* 1 */
     main,  /* 2 */
     summary /* 1 */ {
      display: block;
   }
}

@if $initialize-dinosaur-mode == true {
   /**
      * Add the correct display in IE 9-.
      */
   audio,
   canvas,
   progress,
   video {
      display: inline-block;
   }
}

/**
  * Add the correct display in iOS 4-7.
  */
audio:not([controls]) {
   display: none;
   height: 0;
}

/**
  * Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */
progress {
   vertical-align: baseline;
}

/**
  * Add the correct display in IE 10-.
  * 1. Add the correct display in IE.
  */
template, /* 1 */
 [hidden] {
   display: none;
}

/* ==========================================================================
    Links
    ========================================================================== */

/**
  * 1. Remove the gray background on active links in IE 10.
  * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
  */
a {
   @if $initialize-dinosaur-mode == true {
      background-color: transparent; /* 1 */
   }
   -webkit-text-decoration-skip: objects; /* 2 */
}

/**
  * Remove the outline on focused links when they are also active or hovered
  * in all browsers (opinionated).
  */
a:active,
a:hover {
   outline-width: 0;
}

/* ==========================================================================
    Text-level semantics
    ========================================================================== */

/**
  * 1. Remove the bottom border in Firefox 39-.
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  */
abbr[title] {
   border-bottom: none; /* 1 */
   text-decoration: underline; /* 2 */
   text-decoration: underline dotted; /* 2 */
}

/**
  * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
  */
b,
strong {
   font-weight: inherit;
}

/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */
b,
strong {
   font-weight: 600;
   opacity: 0.75;
   // color: $color-title;
}

/**
  * Add the correct font style in Android 4.3-.
  */
dfn {
   font-style: italic;
}

/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */
// h1 {
//     font-size: 2em;
//     margin: 0.67em 0;
// }

@if $initialize-dinosaur-mode == true {
   /**
     * Address styling not present in IE 8/9.
     */
   mark {
      background: #ff0;
      color: #000;
   }
}

/**
  * Add the correct font size in all browsers.
  */
small {
   font-size: 60%;
}

/**
  * Prevent `sub` and `sup` elements from affecting the line height in
  * all browsers.
  */
sub,
sup {
   font-size: 75%;
   line-height: 0;
   position: relative;
   vertical-align: baseline;
}

sub {
   bottom: -0.25em;
}

sup {
   top: -0.5em;
}

/* ==========================================================================
    Embedded content
    ========================================================================== */

@if $initialize-dinosaur-mode == true {
   /**
      * Remove the border on images inside links in IE 10-.
      */
   img {
      border-style: none;
   }
}

/**
  * Hide the overflow in IE.
  */
svg:not(:root) {
   overflow: hidden;
}

/* ==========================================================================
    Grouping content
    ========================================================================== */

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */
code,
kbd,
pre,
samp {
   font-family: monospace, monospace; /* 1 */
   font-size: 1em; /* 2 */
}

/**
  * Add the correct margin in IE 8.
  */
figure {
   margin: 1em 40px;
}

/**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */
hr {
   box-sizing: content-box; /* 1 */
   height: 0; /* 1 */
   overflow: visible; /* 2 */
}

/* ==========================================================================
    Forms
    ========================================================================== */

/**
  * 1. Change font properties to `inherit` in all browsers (opinionated).
  * 2. Remove the margin in Firefox and Safari.
  */
button,
input,
select,
textarea {
   font: inherit; /* 1 */
   margin: 0; /* 2 */
}

/**
  * Restore the font weight unset by the previous rule.
  */
optgroup {
   font-weight: bold;
}

/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */
button,
input {
   /* 1 */
   overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */
button,
select {
   /* 1 */
   text-transform: none;
}

/**
  * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
  *    controls in Android 4.
  * 2. Correct the inability to style clickable types in iOS and Safari.
  */
button,
 html [type="button"], /* 1 */
 [type="reset"],
 [type="submit"] {
   -webkit-appearance: button; /* 2 */
}

/**
  * Remove the inner border and padding in Firefox.
  */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
   border-style: none;
   padding: 0;
}

/**
  * Restore the focus styles unset by the previous rule.
  */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
   outline: 1px dotted ButtonText;
}

@if $initialize-html5-boilerplate-overwrites == false {
   /**
      * Change the border, margin, and padding in all browsers (opinionated).
      */
   fieldset {
      // border: 1px solid #c0c0c0;
      // margin: 0 2px;
      // padding: 0.35em 0.625em 0.75em;
   }
}

/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */
legend {
   box-sizing: border-box; /* 1 */
   color: inherit; /* 2 */
   display: table; /* 1 */
   max-width: 100%; /* 1 */
   padding: 0; /* 3 */
   white-space: normal; /* 1 */
}

/**
  * Remove the default vertical scrollbar in IE.
  */
textarea {
   overflow: auto;
}

@if $initialize-dinosaur-mode == true {
   /**
      * 1. Add the correct box sizing in IE 10-.
      * 2. Remove the padding in IE 10-.
      */
   [type="checkbox"],
   [type="radio"] {
      box-sizing: border-box; /* 1 */
      padding: 0; /* 2 */
   }
}

/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
   height: auto;
}

/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */
[type="search"] {
   -webkit-appearance: textfield; /* 1 */
   outline-offset: -2px; /* 2 */
}

/**
  * Remove the inner padding and cancel buttons in Chrome and Safari on OS X.
  */
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
   -webkit-appearance: none;
}

/**
  * Correct the text style of placeholders in Chrome, Edge, and Safari.
  */
::-webkit-input-placeholder {
   color: inherit;
   opacity: 0.54;
}

/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */
::-webkit-file-upload-button {
   -webkit-appearance: button; /* 1 */
   font: inherit; /* 2 */
}

@if $initialize-html5-boilerplate-overwrites == true {
   /* ==========================================================================
        HTML5 Boilerplate optimizations
        ========================================================================== */

   /**
      * Remove text-shadow in selection highlight:
      * https://twitter.com/miketaylr/status/12228805301
      *
      * These selection rule sets have to be separate.
      * Customize the background color to match your design.
      */
   ::selection {
      background: #b3d4fc;
      text-shadow: none;
   }

   /**
      * A better looking default horizontal rule
      */
   hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ccc;
      margin: 1em 0;
      padding: 0;
   }

   /**
      * Remove the gap between audio, canvas, iframes,
      * images, videos and the bottom of their containers:
      * https://github.com/h5bp/html5-boilerplate/issues/440
      */
   audio,
   canvas,
   iframe,
   img,
   svg,
   video {
      vertical-align: middle;
   }

   /**
      * Remove default fieldset styles.
      */
   fieldset {
      border: 0;
      margin: 0;
      padding: 0;
   }

   /**
      * Allow only vertical resizing of textareas.
      */
   textarea {
      resize: vertical;
   }
}

/* ==========================================================================
    Optional configurations
    ========================================================================== */

/**
  * Headings
  */
h1,
h2,
h3,
h4,
h5,
h6 {
   @include optional-prop("color", $initialize-heading-color);
   @include optional-prop("margin", $initialize-heading-margins);
   @include optional-prop("font-family", $initialize-heading-font-family);
}

/**
  * Block elements
  */
p,
table,
ul,
ol,
fieldset,
blockquote,
dl {
   @include optional-prop("margin", $initialize-block-margins);
}

/* ==========================================================================
    Print styles
    ========================================================================== */

@if $initialize-print-styles == true {
   @media print {
      *,
      *:before,
      *:after,
      *:first-letter,
      *:first-line {
         background: transparent !important;
         color: #000 !important; // Black prints faster: http://www.sanbeiji.com/archives/953
         box-shadow: none !important;
         text-shadow: none !important;
      }

      a,
      a:visited {
         text-decoration: underline;
      }

      a[href]:after {
         content: " (" attr(href) ")";
      }

      abbr[title]:after {
         content: " (" attr(title) ")";
      }

      // Don't show links that are fragment identifiers,
      // or use the `javascript:` pseudo protocol
      a[href^="#"]:after,
      a[href^="javascript:"]:after {
         content: "";
      }

      pre,
      blockquote {
         border: 1px solid #999;
         page-break-inside: avoid;
      }

      // Printing Tables:
      // http://css-discuss.incutio.com/wiki/Printing_Tables
      thead {
         display: table-header-group;
      }

      tr,
      img {
         page-break-inside: avoid;
      }

      img {
         max-width: 100% !important;
      }

      p,
      h2,
      h3 {
         orphans: 3;
         widows: 3;
      }

      h2,
      h3 {
         page-break-after: avoid;
      }
   }
}
