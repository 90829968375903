@import "../../../assets/scss/general/config";

.e-page-recovery-password {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &__container {
        width: 400px;
        margin: 0 auto;
    }

    .e-box-recovery-password {
        &__header {
            text-align: center;
            margin-bottom: 1.5rem;

            h2 {
                font-size: 1.35rem;
                color: $color-title;
                font-weight: 700;
            }
        }

        &__content {
            form {
                button {
                    margin-top: 1.5rem;
                    font-size: 1rem;
                }

                // text-align: center;

                // .e-label {
                //     display: block;
                //     margin-bottom: 20px;

                //     .e-tt {
                //         display: none;
                //     }

                //     input {
                //         padding: 8px 20px;
                //         width: 100%;
                //         color: #3c4858;
                //         border: 1px solid #e9ecef;
                //         outline: none !important;
                //         border-radius: 8px;

                //         &.e-error {
                //             border: 1px solid #ffa0a0;
                //         }
                //     }
                // }

                .e-back {
                    display: block;
                    margin: 25px 0;
                    color: $color-main;
                    text-align: center;

                    &:hover {
                        color: $color-main-light;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
