@import "../../assets/scss/general/config";

html {
    body {
        .e-site {
            .e-main-page {
                &.e-page-resources {
                    .e-resources {
                        background-color: #f6f7fa;
                        padding: 60px 0;

                        &__container {
                            max-width: 1140px;
                            margin: 0 auto;
                        }

                        .e-title {
                            font-size: 38px;
                            font-weight: bold;
                            color: $color-title;
                            line-height: 1.2;
                            margin-bottom: 60px;

                            span {
                                color: $color-main;
                            }
                        }

                        &__boxs {
                            display: -ms-flexbox;
                            display: flex;
                            -ms-flex-wrap: wrap;
                            flex-wrap: wrap;
                            margin-right: -15px;
                            margin-left: -15px;

                            .e-box {
                                position: relative;
                                background-color: #fff;
                                border-radius: 5px;
                                padding: 30px 30px 26px;
                                -webkit-box-shadow: 0px 0px 20px 0px #dddddd7a;
                                box-shadow: 0px 0px 20px 0px #dddddd7a;
                                z-index: 1;
                                -webkit-transition: 0.5s all ease;
                                transition: 0.5s all ease;
                                overflow: hidden;
                                margin-bottom: 30px;

                                flex: 0 0 33.333333%;
                                max-width: 30.333333%;
                                margin: 0 1.3% 3%;

                                &__title {
                                    font-size: 18px;
                                    font-weight: bold;
                                    color: $color-title;
                                    line-height: 1.2;
                                    margin-bottom: 20px;
                                }

                                p {
                                    font-size: 15px;
                                    line-height: 1.5;
                                    color: #8492a6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
