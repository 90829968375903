.e-footer {
   background-color: #222131;

   &__container {
      max-width: 1140px;
      margin: 0 auto;
   }

   &__columns {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 50px 0;
      line-height: 1.6;
      color: #fff;

      &--items {
         h4 {
            font-size: 16px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 15px;
         }

         ul {
            li {
               color: #fff;
               font-size: 14px;
               margin-bottom: 8px;

               a {
                  color: #fff;

                  &:hover {
                     text-decoration: underline;
                  }
               }
            }
         }

         .e-logo-footer {
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            letter-spacing: 1px;
            margin-bottom: 20px;
         }

         .e-description-footer {
            font-size: 14px;
            line-height: 1.5;
         }
      }
   }
}

.e-footer-sub {
   background-color: #302f3e;
   color: #fff;
   padding: 16px 0 12px;
   font-size: 14px;

   &__container {
      max-width: 1140px;
      margin: 0 auto;
   }
}
