@import "../../assets/scss/general/config";

.e-page-login {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @media only screen and (max-width: 900px) {
            flex-direction: column;
        }
    }

    .e-box-bg {
        display: inline-block;
        text-align: center;
        width: 40%;

        img {
            max-width: 100%;
            height: auto;
            vertical-align: middle;
        }

        @media only screen and (max-width: 900px) {
            display: none;
        }
    }

    .e-box-login {
        display: inline-block;
        width: 30%;
        max-width: 400px;
        margin: 0 80px;

        &__header {
            text-align: center;
            margin-bottom: 30px;

            .e-title {
                display: inline-block;
                font-size: 28px;
                font-weight: 900;
                letter-spacing: 1px;
                color: $color-title;
            }
        }

        &__container {
            max-width: 400px;
            margin: 0 auto;
        }

        .e-login-google {
            display: none;
        }

        .e-spacer {
            display: none;
        }

        .e-form-login {
            .e-label {
                display: block;
                margin-bottom: 20px;

                .e-tt {
                    display: none;
                }

                // input {
                //     padding: 8px 20px;
                //     width: 100%;
                //     color: $color-title;
                //     border: 1px solid #e9ecef;
                //     outline: none !important;
                //     border-radius: 8px;

                //     &.e-error {
                //         border: 1px solid #ffa0a0;
                //     }
                // }
            }

            .e-btn-login {
                display: block;
                width: 100%;
                background-color: $color-main;
                color: #fff;
                padding: 8px 20px;
                outline: none;
                text-decoration: none;
                font-size: 16px;
                letter-spacing: 0.5px;
                transition: all 0.3s;
                font-weight: 600;
                border-radius: 6px;
                border: 1px solid $color-main;
                box-shadow: 0 3px 5px 0 rgba($color-main, 0.3);
                cursor: pointer;

                &:hover {
                    background-color: $color-main !important;
                    border-color: $color-main !important;
                }
            }
        }

        .e-links {
            text-align: center;
            margin: 25px 0;

            a {
                color: $color-main;

                &:hover {
                    text-decoration: underline;
                }
            }

            h6 {
                margin-top: 10px;
            }
        }

        @media only screen and (max-width: 900px) {
            width: 100%;
        }
    }
}

.e-recover .e-content {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    h2 {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: $color-title;
        font-weight: 700;
    }

    form {
        width: 100%;

        .input-container {
            margin: 0.5rem 0;
        }

        button {
            font-size: 1rem;
            margin: 1rem 0;
        }
    }
}
