@import "../../../assets/scss/general/config";

html {
    body {
        .e-site {
            .e-main-page {
                .e-contact {
                    background-color: #f6f7fa;
                    padding: 60px 0;

                    &__container {
                        max-width: 1140px;
                        margin: 0 auto;

                        @media only screen and (max-width: 1000px) {
                            width: 90vw;
                        }
                    }

                    .e-title {
                        font-size: 38px;
                        font-weight: bold;
                        color: $color-title;
                        line-height: 1.2;
                        margin-bottom: 40px;

                        span {
                            color: $color-main;
                        }
                    }

                    &__boxs {
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;

                        p {
                            font-size: 18px;
                            line-height: 1.5;
                            color: #8492a6;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
}
