@import "./general/config";
@import "./general/reset";
@import "./general/initialize";
@import "./general/fonts";
@import "./general/breakpoint";

/*components page*/
@import "./components/notification";


html {
   body {
      color: $color-title;
      background: #fff;

      // style general site
      .e-site {
         font-family: "Nunito", sans-serif;

         .e-main-page {
            padding-top: 80px;
         }
      }
   }
}
